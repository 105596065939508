import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useContent } from '../../../../../services/Content/getContent';
import { Loading } from '../../../../../components/Loading';
import { ReadMore } from '../../../../../components/Readmore';
import { getPostedByName } from '../../../../../utils/getPostedByName';
import { formatDate } from '../../../../../utils/getTimeFrom';
import { largeNumber } from '../../../../../utils/NumberFormat';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getContentLink } from '../../../../../utils/getContentLink';

type ContentDetailsProps = {
    id: string
}

export const ContentDetails = ({ id }: ContentDetailsProps) => {
    const { closeModal } = useModal();
    const { data, loading } = useContent({
        filters: {
            ids: id
        },
        limit: 1,
        name: 'getContentDetails',
        sort: 'date',
        fragments: ['Metadata', 'Document', 'Engagement']
    });
    if (loading) return <Loading relative height={200} />;
    const content = data[0];
    return (
        <div>
            <ReadMore text={content.text} limit={400} className="font-weight-bold" />
            {content.text !== content.body && <ReadMore text={content.body} limit={400} />}
            <hr />
            <p>Engagement: {content.engagement === -1 ? 'N/A' : largeNumber(content.engagement)}</p>
            <p>Posted by: {getPostedByName(content.from, content.display_name)}</p>
            <p>Posted on: {formatDate(content.pubdatetime, 'DD/MM/YYYY @ HH:mm z')}</p>
            <hr />
            <Button onClick={closeModal} className="mr-2">Close</Button>
            <Link to={getContentLink({ id })} onClick={closeModal}>
                <Button color="primary">View content source</Button>
            </Link>
        </div>
    );
};
