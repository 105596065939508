import moment from 'moment';
import { convertESInterval } from './convertESInterval';

export const getIntervalDateRange = (startDate, interval) => {
    const intervalMilliseconds = convertESInterval(interval, startDate);
    const dateFormat = intervalMilliseconds <= (24 * 60 * 60 * 1000) ? 'DD/MM h:mm A' : 'DD/MM/YYYY';
    const shouldShowRange = interval;
    const endDate = getEndDate(startDate, interval);
    return `${moment(new Date(startDate)).format(dateFormat)}${shouldShowRange
        ? ` - ${moment(endDate).format(dateFormat)}`
        : ''
    }`;
};

export const getEndDate = (startDate, interval) => {
    if (!interval) { return new Date(); }
    const date = new Date(startDate);
    // years
    if (interval.includes('y')) {
        const years = parseInt(interval.replace('y', ''), 10);
        return new Date(date.getFullYear() + years, date.getMonth(), date.getDate());
    }
    // months
    if (interval.includes('M')) {
        const months = parseInt(interval.replace('M', ''), 10);
        return new Date(date.getFullYear(), date.getMonth() + months, 0);
    }
    // weeks
    if (interval.includes('w')) {
        const weeks = parseInt(interval.replace('w', ''), 10);
        date.setDate(date.getDate() + weeks * 7);
        return date;
    }
    // days
    if (interval.includes('d')) {
        const days = parseInt(interval.replace('d', ''), 10);
        date.setDate(date.getDate() + days);
        return date;
    }
    // hours
    if (interval.includes('h')) {
        const hours = parseInt(interval.replace('h', ''), 10);
        date.setHours(date.getHours() + hours);
        return date;
    }
};

export const getStartDate = (endDate, interval) => {
    if (!interval) { return new Date(); }
    const date = new Date(endDate);
    if (interval === 'All') {
        date.setFullYear(date.getFullYear() - 100);
        return date;
    }
    // years
    if (interval.includes('y')) {
        const years = parseInt(interval.replace('y', ''), 10);
        date.setFullYear(date.getFullYear() - years);
        return date;
    }
    // months
    if (interval.toLowerCase().includes('m')) {
        const months = parseInt(interval.toLowerCase().replace('m', ''), 10);
        date.setMonth(date.getMonth() - months);
        return date;
    }
    // weeks
    if (interval.includes('w')) {
        const weeks = parseInt(interval.replace('w', ''), 10);
        date.setDate(date.getDate() - weeks * 7);
        return date;
    }
    // days
    if (interval.includes('d')) {
        const days = parseInt(interval.replace('d', ''), 10);
        date.setDate(date.getDate() - days);
        return date;
    }
    // hours
    if (interval.includes('h')) {
        const hours = parseInt(interval.replace('h', ''), 10);
        date.setHours(date.getHours() - hours);
        return date;
    }
};
