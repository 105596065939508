import React from 'react';

export const getFoundText = (type, name) => {
    if (!name) return '';
    let append = '';
    let prepend = 'found for';
    if (['ctype', 'ctype__keyword', 'from__keyword'].includes(type)) {
        prepend = 'from';
    }
    if (type === 'sentiment__keyword') {
        append = 'sentiment';
    }
    if (type === 'lang_verbose__keyword') {
        append = 'language';
    }
    return <span>{prepend} <strong>{name} {append}</strong></span>;
};
