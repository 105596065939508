import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useUser } from '../../../context/user/UserContext';
import { SituationRoomsDropdown } from './SituationRoomsDropdown';
import { getRoom } from '../../../utils/variables';

export const TopBar = ({ currentPage }) => {
    const { user } = useUser();
    const room = getRoom();
    return (
        <Row className="align-items-center justify-content-between px-4 pb-2 border-bottom">
            <Col xs="auto">
                <SituationRoomsDropdown currentPage={currentPage} />
            </Col>
            {user.role === 'ADMIN' && (
                <Col xs="auto">
                    <Link to={`/situation-room/edit-room/${room.id}`}>
                        Edit room
                    </Link>
                </Col>
            )}
        </Row>
    );
};
