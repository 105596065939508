import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';

// TODO: Test this
export const selectExtremesMin = createSelector(
    (state) => state.overview.visualisation.multiSmallCharts,
    ({ userMin }) => userMin
);
export const selectExtremesMax = createSelector(
    (state) => state.overview.visualisation.multiSmallCharts,
    ({ userMax }) => userMax
);
export const selectRange = createSelector(
    (state) => state.overview.visualisation.multiSmallCharts,
    ({ range }) => range
);

const initialState = {
    visualisation: {
        multiSmallCharts: {
            range: null,
            userMin: undefined,
            userMax: undefined
        }
    }
};

const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers: {
        setRangeAction: (state, { payload }) => ({
            ...state,
            visualisation: {
                multiSmallCharts: {
                    ...state.visualisation.multiSmallCharts,
                    range: payload
                }
            }
        }),
        storeExtremesAction: (state, { payload: { userMin, userMax } }) => ({
            ...state,
            visualisation: {
                multiSmallCharts: {
                    ...state.visualisation.multiSmallCharts,
                    userMin,
                    userMax
                }
            }
        }),
        resetMultiSmallChartsAction: (state) => ({
            ...state,
            visualisation: {
                multiSmallCharts: initialState.visualisation.multiSmallCharts
            }
        })
    }
});

export const { setRangeAction, storeExtremesAction, resetMultiSmallChartsAction } = overviewSlice.actions;
export const overview = overviewSlice.reducer;
