import auth0 from 'auth0-js';
import { useEffect, useState } from 'react';

const nodeEnv = window.REACT_APP_ENV || 'local';

const getAuth0Config = () => {
    if (nodeEnv === 'production') {
        return {
            domain: 'login.logically.ai',
            clientID: 'CA4DlAdb3AZNTCIAqbhC512Vy6QfCOcv'
        };
    }
    return {
        domain: 'logically-staging.us.auth0.com',
        clientID: 'GGa1GLZ3J8qEDihcBPLdjvM0fRtVpfnz'
    };
};

export const client = new auth0.WebAuth(getAuth0Config());

export const getToken = async () => {
    const checkSession = new Promise((resolve) => {
        client.checkSession({
            responseType: 'id_token',
            scope: 'openid email',
            redirectUri: window.location.origin,
        }, (err, data) => {
            if (err) {
                resolve({});
            } else {
                resolve(data);
            }
        });
    });
    const data = await checkSession;
    return data?.idToken;
};

export const verifyPassword = (email, password) => new Promise((resolve) => {
    client.popup.loginWithCredentials({
        email,
        password,
        responseType: 'id_token',
        scope: 'openid',
        realm: 'Username-Password-Authentication',
        redirectUri: window.location.href
    }, (err, b) => {
        if (b?.idToken) {
            resolve(true);
        } else if (err.code === 'consent_required') {
            client.login({
                email,
                password,
                responseType: 'id_token',
                scope: 'openid',
                realm: 'Username-Password-Authentication',
                redirectUri: false
            }, (_err) => {
                if (_err) {
                    resolve(false);
                } else {
                    resolve(true);
                }
            });
        } else {
            resolve(false);
        }
    });
});

export const useSessionUser = () => {
    const [user, setUser] = useState({});
    const [idToken, setIdToken] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        client.checkSession({
            responseType: 'id_token',
            scope: 'openid profile email user_metadata',
            realm: 'Username-Password-Authentication',
            redirectUri: window.location.origin,
        }, (err, authResult) => {
            if (authResult?.idTokenPayload) {
                const usr = authResult.idTokenPayload;
                setIdToken(authResult.idToken);
                setUser({
                    uid: usr.sub.replace('auth0|', ''),
                    email: usr.email,
                    displayName: usr.name,
                    photoURL: usr.picture || ''
                });
            }
            setLoading(false);
        });
    }, []);

    return { user, loading, idToken };
};
