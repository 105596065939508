import React, { useMemo } from 'react';
import { SourcesList } from './SourcesList';
import { TotalMatchesAndThreatsOverTime } from './TotalMatchesAndThreatsOverTime';
import { CurrentSentiment } from './Pie/CurrentSentiment';
import { Wordcloud } from './Wordcloud';
import { TermAnalysis } from './TermAnalysis';
import { Matches } from '../Matches';
import { TopLocationMentions } from './Table/TopLocationMentions';
import { TopSources } from './Table/TopSources';
import { TopLanguages } from './Table/TopLanguages';
import { TopPublishers } from './Table/TopPublishers';
import { TopSharedURLs } from './Table/TopSharedURLs';

export const Visualisation = ({ type, data, id }) => {
    const ret = useMemo(() => {
        if (id === 'sourcesList') {
            return <SourcesList />;
        }
        if (id === 'totalMatchesAndThreatsOverTime') {
            return <TotalMatchesAndThreatsOverTime items={data.items} />;
        }
        if (id === 'currentSentiment') {
            return <CurrentSentiment />;
        }
        if (id === 'topSharedUrls') {
            return <TopSharedURLs />;
        }
        if (id === 'topPublishers') {
            return <TopPublishers />;
        }
        if (id === 'topSources') {
            return <TopSources />;
        }
        if (id === 'locationMentions') {
            return <TopLocationMentions />;
        }
        if (id === 'topLanguages') {
            return <TopLanguages />;
        }
        if (type === 'wordcloud') {
            return <Wordcloud />;
        }
        if (type === 'termAnalysis') {
            return <TermAnalysis />;
        }
        if (type === 'matches') {
            return <Matches />;
        }
    }, [data, id, type]);
    return (
        <div className="h-100">
            {ret}
        </div>
    );
};
