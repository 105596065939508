import React, { useCallback, useEffect, useRef, useState } from 'react';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ResizeObserver } from 'resize-observer';

type ResponsiveChartProps = {
    options: Options
}

export const ResponsiveChart = ({ options }: ResponsiveChartProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const highchartRef = useRef<HighchartsReact.RefObject>(null);
    const getSize = useCallback(() => {
        if (!ref || !ref.current) { return window.innerWidth - 100; }
        return ref.current.offsetWidth - 48;
    }, []);
    const [size, setSize] = useState(getSize());

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        const handleResize = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                const newSize = getSize();
                if (newSize !== size && setSize) {
                    setSize(newSize);
                    highchartRef?.current?.chart?.reflow();
                }
            }, 300);
        };
        handleResize();
        if (!ref?.current) { return () => clearTimeout(timer); }
        const ro = new ResizeObserver(handleResize);
        ro.observe(ref.current);
        const current = ref?.current;
        return () => {
            clearTimeout(timer);
            if (current) {
                ro.unobserve(current);
            }
        };
    }, [ref, getSize, size]);
    return (
        <div ref={ref} className="h-100">
            <HighchartsReact options={options}
                highcharts={Highcharts}
                ref={highchartRef}
                containerProps={{ style: { height: '100%' } }}
            />
        </div>
    );
};
