import { gql } from '@apollo/client';

export const situationRoomFragments = gql`
    fragment SituationRoomMetadata on SituationRoom {
        id
        project_name
        project_id
        is_active
        archived
        createdAt
        limit
        updatedAt
        action
        paused,
        private,
        start_date,
        active,
        query_type
        reason
        contentVisibilityRestriction
    }

    fragment SituationRoomUser on UserMongo {
        id
        email
        role
        displayName
        firstName
        lastName
        uid
    }

    fragment SituationRoomCreatedBy on UserMongo {
        displayName
        firstName
        lastName
        email
        photoURL
        id
        uid
        role
    }
`;

export const situationRoomsFragments = gql`
    fragment SituationRoomsMetadata on SituationRoom {
        id
        project_name
        project_id
        is_active
        active
        action
        createdAt
        updatedAt
        private
        archived
        archivedDetails {
            archivedBy {
                displayName
            }
            archivedAt
            autoArchived
        }
        deleted
        paused
        query_type
    }

    fragment SituationRoomsCreatedBy on UserMongo {
        id
        email
        displayName
        firstName
        lastName
    }
    
`;

export const queryFragments = gql`
    fragment FullQuery on QueryType {
        boolean
        included{
            type
            value
            accountType
            fromAccount
            toAccount
        }
        excluded{
            type
            value
            accountType
            fromAccount
            toAccount
        }
    }
`;
