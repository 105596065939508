import { useSources } from '../../../../../services/Overview';
import { withTableStructure } from './withTableStructure';

const columns = [
    { key: 'source', label: 'Platform', type: 'contentType' },
    { key: 'count', label: 'Matches' },
    { key: 'threats', label: 'Threats' }
];

const config = {
    api: useSources,
    apiParams: { includeThreats: true },
    title: ({ preview }) => `${!preview ? 'Top 10 ' : ''}Sources`,
    tooltip: `A breakdown of the sources where we’ve detected matching content for this room, and the matches and
    threats from each source.`,
    columns
};

export const TopSources = withTableStructure(config);
