import { gql, useMutation } from '@apollo/client';
import { isEqual, isNil, omitBy } from 'lodash';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { ModalWithAction } from '../../../components/Molecules';
import { useModal } from '../../../context/modal/ModalComponent';
import { useOverview } from '../../../context/overview/OverviewContext';
import { getRoom } from '../../../utils/variables';
import { standardTemplate } from './Templates/Standard';

export const OverviewEditBar = () => {
    const { setModal } = useModal();
    const { handleCancel, handleSave, hasChanges, layout, setLayout } = useOverview();
    const room = getRoom();
    const [updateRoomOverview, { loading }] = useMutation(UPDATE_ROOM_OVERVIEW);
    const handleUpdate = () => {
        updateRoomOverview({
            variables: {
                data: {
                    ...layout,
                    situationRoom: room.id
                }
            }
        }).then(handleSave);
    };
    const formattedStandardTemplate = {
        ...standardTemplate,
        data: standardTemplate.data.map(widget => ({
            ...widget,
            removed: layout.data.find(({ id }) => widget.id === id).removed || false
        }))
    };
    const canResetLayout = !isEqual(
        formattedStandardTemplate.data,
        layout.data.map(item => ({
            ...(omitBy(item, isNil)),
            data: omitBy(item.data, (value) => {
                if (value === null || value === undefined) return true;
                return typeof value === 'object' ? !Object.keys(value).length : false;
            })
        }))
    );
    const openCancelModal = () => {
        if (hasChanges) {
            setModal({
                component: <ModalWithAction heading="Are you sure you want to cancel?"
                    subheading="Changes you made will not be saved."
                    action={{ onClick: handleCancel, name: 'Yes, Cancel' }}
                />
            });
        } else {
            handleCancel();
        }
    };
    const openResetModal = () => setModal({
        component: <ModalWithAction heading="Are you sure you want to reset the overview?"
            subheading="This action will reset the layout to original view."
            action={{
                onClick: () => setLayout(formattedStandardTemplate),
                name: 'Yes, Reset'
            }}
        />
    });
    const openSaveModal = () => setModal({
        component: <ModalWithAction heading="Are you sure you want to save the overview?"
            subheading="This action will save the new layout."
            action={{
                onClick: handleUpdate,
                name: 'Yes, Save'
            }}
        />
    });
    return (
        <div className="position-absolute top-right w-100 h-100">
            <Row className={`justify-content-between px-4 py-2 bg-white 
            border-bottom border-primary position-sticky top-right z-index`}
            >
                <Col xs={12} lg={6} xl={8}>
                    <p className="m-0"><strong>You are editing this overview dashboard</strong></p>
                    <p className="m-0">
                        You can resize the widgets and change their placement. The new layout will only be visible to you.
                    </p>
                </Col>
                <Col xs={12} lg="auto" className="mb-n2">
                    {canResetLayout && <Button color="link" className="mb-2" onClick={openResetModal}>Reset layout</Button>}
                    <Button color="primary" className="mr-2 mb-2" onClick={openSaveModal} disabled={!hasChanges || loading}>
                        Save
                    </Button>
                    <Button onClick={openCancelModal} disabled={loading} className="mb-2">Cancel</Button>
                </Col>
            </Row>
        </div>
    );
};

export const UPDATE_ROOM_OVERVIEW = gql`
    mutation updateRoomOverview($data: RoomOverviewInput!) {
        updateRoomOverview(data: $data) {
            id
        }
    }
`;
