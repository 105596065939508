import { useTopSharedUrls } from '../../../../../services/Overview';
import { withTableStructure } from './withTableStructure';

const columns = [
    { key: 'url', label: 'URL', type: 'url' },
    { key: 'count', label: 'Shared' }
];

const config = {
    api: useTopSharedUrls,
    title: ({ preview }) => `Top ${!preview ? '10 ' : ''}shared URLs`,
    tooltip: 'This is a high-level summary of the Top 10 most shared URLs and how often they’ve been shared.',
    columns
};

export const TopSharedURLs = withTableStructure(config);
