import React, { useState } from 'react';
import { Carousel, CarouselIndicators, CarouselItem } from 'reactstrap';
import slide1image from './slide-1.png';
import slide2image from './slide-2.png';
import slide3image from './slide-3.png';

const items = [
    {
        src: slide1image,
        altText: '',
        caption: 'Welcome to Logically Intelligence',
        subText: 'See what’s new in Logically Intelligence.',
        key: 1,
    },
    {
        src: slide2image,
        altText: '',
        caption: 'Want to monitor older content?',
        subText: 'You can now set a situation room’s start date beyond the last six months.',
        key: 2,
    },
    {
        src: slide3image,
        altText: '',
        caption: 'Exports reports',
        subText: 'You can now export reports as Powerpoint files as well as PDFs.',
        key: 3,
    }
];

export const WhatsNewCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map((item) => (
        <CarouselItem onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.src}
        >
            <img src={item.src} alt={item.altText} className="mb-3" />
            <h2>{item.caption}</h2>
            <p className="mb-3">{item.subText}</p>
        </CarouselItem>
    ));
    return (
        <Carousel activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={5000}
            className="carousel-fade"
            slide={false}
        >
            {slides}
            <CarouselIndicators items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
                className="custom-carousel-indicators"
            />
        </Carousel>
    );
};
