import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { ArrowLeft } from 'react-feather';
import { AuthLayout } from './AuthLayout';
import { getParam } from '../../utils/urlParams';
import { EmailForm } from './EmailForm';
import { SSOLogin } from './SSOLogin';
import { LoginForm } from './LoginForm';
import { ForgotPassword } from './ForgotPassword';
import { client } from '../../auth0';

export const Login = () => {
    const [email, setEmail] = useState(getParam('ssoemail') || '');
    const defaultConnectionName = getParam('ssocn') || '';
    const [forgotPasswordFlow, setForgotPasswordFlow] = useState(false);
    const [usingCustomConnection, setUsingCustomConnection] = useState(false);

    const loginWithCustomConnection = (ssocn) => {
        setUsingCustomConnection(true);
        client.authorize({
            connection: ssocn,
            responseType: 'token id_token',
            scope: 'openid profile email',
            redirectUri: `${window.location.origin}/sso-auth?ssocn=${ssocn}`
        });
    };

    return (
        <AuthLayout>
            <div className="login-form">
                {forgotPasswordFlow ? (
                    <>
                        <Button color="link" className="d-flex align-items-center mb-2 p-0" onClick={() => setForgotPasswordFlow(false)}>
                            <ArrowLeft size={18} />
                            <p className="my-0 ml-2 text-primary">Back to login</p>
                        </Button>
                        <ForgotPassword />
                    </>
                ) : (
                    <>
                        <h1>Login</h1>
                        <p>
                            {!email && 'Enter your email address to continue.'}
                            {email && defaultConnectionName && 'Use single sign-on solution to login.'}
                            {email && !defaultConnectionName && 'Enter your password to login.'}
                        </p>
                        <hr />
                        <EmailForm email={email}
                            onChange={(emailData) => {
                                setEmail(emailData.email);
                                if (emailData.connectionName) {
                                    loginWithCustomConnection(emailData.connectionName);
                                }
                            }}
                        >
                            <div>
                                {usingCustomConnection ? (
                                    <Spinner className="mt-2" color="primary" size="sm" />
                                ) : (
                                    <div>
                                        {defaultConnectionName ? (
                                            <SSOLogin connectionName={defaultConnectionName} />
                                        ) : <LoginForm email={email} setForgotPasswordFlow={setForgotPasswordFlow} />}
                                    </div>
                                )}
                            </div>
                        </EmailForm>
                    </>
                )}
            </div>
        </AuthLayout>
    );
};
