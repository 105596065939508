import React from 'react';
import { Options } from 'highcharts';
import { largeNumber } from '../../utils/NumberFormat';
import { Loading } from '../Loading';
import { ResponsiveChart } from './ResponsiveChart';

export { MatchesWidget } from './MatchesWidget';

type PieChartProps = {
    data: number[];
    labels: string[];
    colors: string[];
    legendEnabled?: boolean;
    height?: number;
    innerSize?: string;
    width?: number;
}

export const PieChart = ({
    data,
    labels,
    colors,
    legendEnabled = true,
    height = 300,
    innerSize = '50%',
    width
}: PieChartProps) => {
    if (!data) {
        return (
            <div data-testid="pie-chart-loader">
                <Loading relative />
            </div>
        );
    }
    const options: Options = {
        title: {
            text: ''
        },
        chart: {
            backgroundColor: 'transparent',
            spacingLeft: 0,
            ...(JSON.stringify(height) === '100%' ? {} : { height }),
            ...(JSON.stringify(width) === '100%' ? {} : { width })
        },
        colors,
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        tooltip: {
            useHTML: true,
            headerFormat: '',
            backgroundColor: 'rgba(246, 246, 246, 1)',
            style: {
                opacity: 1,
                background: 'rgba(246, 246, 246, 1)'
            },
            pointFormatter() {
                const percentage = (100 * (this as any).y) / (this as any).total;
                const { color, name } = this;
                return (
                    `<div style="opacity:1;">
                    <div style="display:flex; align-items:center;">
                    Sentiment&nbsp;
                    <div style="margin-left:10px; height: 10px; width: 10px; border-radius: 2; background-color: ${color}">
                    </div>
                    <span style="padding-left:5px;">${name}</span></div><hr style="margin:0"/>
                    <div>Matched&nbsp;<span style="float:right">${largeNumber((this as any).y)}</span></div><hr style="margin:0"/>
                    <div>%&nbsp;<span style="float:right">${Math.round(percentage)}</span></div>
                    </div>`);
            }
        },
        legend: {
            enabled: legendEnabled,
            layout: 'vertical',
            verticalAlign: 'middle',
            align: 'right',
            itemMarginBottom: 8,
            itemMarginTop: 8,
            symbolHeight: 24,
            symbolWidth: 8,
            squareSymbol: false,
            symbolRadius: 4,
            itemStyle: {
                transform: 'translate(0, -6px)'
            },
        },
        series: [{
            type: 'pie',
            innerSize,
            data: data.map((item, i: number) => ([labels[i], item]))
        }]
    };

    return (
        <div className="flex-1" data-testid="pie-chart" style={{ height: 'calc(100% - 90px)' }}>
            <ResponsiveChart options={options} />
        </div>
    );
};
