export const largeNumber = (value) => {
    if (value <= 1000) {
        return value;
    }

    const suffixes = ['', 'k', 'm', 'b', 't'];
    const suffixNum = Math.floor((`${value}`).length / 3);
    let shortValue = '';

    for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
            (suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(precision)
        );
        const dotLessShortValue = (`${shortValue}`).replace(/[^a-zA-Z 0-9]+/g, '');
        if (dotLessShortValue.length <= 2) {
            break;
        }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);

    return shortValue + suffixes[suffixNum];
};

export const roundPercentage = (percentage) => {
    let ret = Math.round(percentage);
    if (ret < 10) {
        ret = Math.round(percentage * 10) / 10;
        if (ret < 1) {
            ret = Math.round(percentage * 1000) / 1000;
            if (ret === 0.1) {
                ret = Math.round(percentage * 10000) / 10000;
            }
        }
    }
    return ret;
};
