import React from 'react';
import { useSelector } from 'react-redux';
import { EmptySearchResults } from '../../../../../components/EmptySearchResults';

export const EmptyContentSearchResults = () => {
    const filters = useSelector((state) => state.filters.filters);
    const hasFilters = filters.origin?.length > 0
      || filters.sentiment?.length > 0
      || filters.sources?.length > 0
      || filters.dateRange?.startDate
      || filters.query
      || filters.threatTypes?.length > 0
      || filters.booleanQuery;
    if (hasFilters) {
        return (
            <EmptySearchResults mainHeading="No search results"
                subtitle="Your query returned no results, please try again"
            />
        );
    }
    return <EmptySearchResults />;
};
