import { get } from 'lodash';
import React from 'react';
import { Input } from '../../../../components/Form/Input';
import { useAdminUser } from '../../../../context/adminUser/AdminUserContext';
import { useInstance } from './InstanceContext';

export const SummaryForm = () => {
    const { instance, handleChange, error } = useInstance();
    const { user } = useAdminUser();
    const fields = [
        { label: 'Client name', field: 'clientName', required: true },
        { label: 'Contact name', field: 'account.contactName' },
        { label: 'Role/Position', field: 'account.role' },
        { label: 'Email address', field: 'account.email' },
        { label: 'Phone number', field: 'account.phoneNumber' },
        { label: 'Location', field: 'account.location' },
        { label: 'Contract link', field: 'account.contractLink' },
        { label: 'Account owner', field: 'account.accountManager' },
        { label: 'Notes', field: 'account.notes', type: 'textarea' }
    ];
    return (
        <div>
            <h3 className="m-0">Summary</h3>
            <hr className="my-3" />
            {fields.map(({ label, field, type, required }) => (
                <div className="mb-3" key={field}>
                    <Input label={label}
                        value={get(instance, field)}
                        onChange={(e) => handleChange(e.target.value, field)}
                        type={type}
                        required={required}
                        error={error?.field === field ? error.message : ''}
                        state={error?.field === field ? 'error' : ''}
                        disabled={user.role !== 'ADMIN'}
                    />
                </div>
            ))}
        </div>
    );
};
