import React from 'react';
import Parser, { HTMLReactParserOptions, domToReact, Element, DOMNode } from 'html-react-parser';

export const getHighlightedContent = (text: string) => {
    if (!text) {
        return [];
    }
    const options = {
        replace: (domNode) => {
            // although content does get parsed on the backend
            // to ensure we only render em tags for highlights, we are replacing everything else with spans
            if (domNode instanceof Element && domNode.name !== 'em') {
                return (
                    <span>
                        {domToReact(domNode.children as DOMNode[], options)}
                    </span>
                );
            }
        }
    } as HTMLReactParserOptions;
    return Parser(text.replaceAll('<em>', '<em class="highlight-em">'), options);
};
