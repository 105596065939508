import { gql, useQuery } from '@apollo/client';
import { useContentFilters } from '../../pages/Room/useFilters';

export const useTotalThreatsAndMatches = ({ dateFilter, showFull }) => {
    const defaultFilters = (dateFilter?.startDate || dateFilter?.endDate) ? { date: dateFilter } : {};
    const filters = useContentFilters({ useGlobalFilters: true, defaultFilters });
    const ret = useQuery(TOTAL_MATCHES_AND_THREATS_OVER_TIME, {
        variables: {
            ...filters,
            interval: showFull ? '1d' : '20'
        }
    });

    return {
        ...ret,
        data: ret.data ? ret.data?.totalMatchesAndThreatsOverTime || {} : {}
    };
};

export const TOTAL_MATCHES_AND_THREATS_OVER_TIME = gql`
    query totalMatchesAndThreatsOverTime(
        $projectId: String
        $filters: ContentFilters
        $dslQuery: JSON
        $interval: String
    ) {
        totalMatchesAndThreatsOverTime(
            projectId: $projectId
            filters: $filters
            dslQuery: $dslQuery
            interval: $interval
        ) {
            matches {
                data {
                    key
                    count
                }
                count
            }
            threats {
                data {
                    key
                    count
                }
                count
            }
            interval
        }
    }
`;
