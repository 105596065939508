import React from 'react';
import { useMutation } from '@apollo/client';
import { Button } from 'reactstrap';
import { Loading } from '../../components/Loading';
import { UPDATE_SITUATION_ROOM } from '../../graphql';
import { Archived } from '../../assets/svg/archived';

export const UnarchiveModal = ({ id, closeModal }) => {
    const [updateSituationRoom, { loading }] = useMutation(UPDATE_SITUATION_ROOM, {
        onCompleted: () => {
            closeModal();
        },
        refetchQueries: [
            'situationRooms'
        ]
    });
    const activateRoom = () => {
        updateSituationRoom({
            variables: {
                id,
                archived: false,
                action: 'create',
                is_active: true
            }
        });
    };
    if (loading) {
        return <Loading height={200} relative />;
    }
    return (
        <div>
            <Archived />
            <h2>This room is archived</h2>
            <hr />
            <p>Press “Activate room” to unarchive the Situation Room and access all data prior to the Situation Room being archived.</p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Close</Button>
            <Button color="primary" onClick={activateRoom}>Activate room</Button>
        </div>
    );
};
