import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Move, Trash } from 'react-feather';
import Highcharts from 'highcharts';
import { Box } from './Box';
import { Visualisation } from '../Visualisation';
import { useOverview } from '../../../../context/overview/OverviewContext';
import { ResizeHandle } from './ResizeHandle';
import { useModal } from '../../../../context/modal/ModalComponent';
import { ModalWithAction } from '../../../../components/Molecules';

const GridLayout = WidthProvider(Responsive);

export const Layout = () => {
    const { setModal } = useModal();
    const { edit, layout: { data: rawData, ...layout }, setLayout } = useOverview();
    const data = rawData.filter(item => !item.removed);
    const largeLayout = data.map((item) => ({
        i: item.id,
        x: item.x,
        y: item.y,
        h: item.h,
        w: item.w,
        minH: item.minH,
        minW: item.minW,
        maxH: item.maxH,
        maxW: item.maxW
    }));
    const deleteWidget = (widget) => {
        setLayout({
            ...layout,
            data: rawData.map(item => {
                if (item.id === widget.id) {
                    return {
                        ...item,
                        removed: true,
                        dateRemoved: new Date()
                    };
                }
                return item;
            })
        });
    };
    const openDeleteWidgetModal = (widget) => setModal({
        component: <ModalWithAction heading="Are you sure want to remove the widget from overview?"
            subheading={`The widget will be removed from your layout with this action, but it can be restored later if desired.
             In edit layout mode, the removed widgets are visible at the top of the page.`}
            action={{
                onClick: () => deleteWidget(widget),
                name: 'Yes, Remove'
            }}
        />
    });
    return (
        <GridLayout rowHeight={2}
            layouts={{
                lg: largeLayout,
                sm: largeLayout.map(item => ({ ...item, w: 6, minW: 6 }))
            }}
            breakpoints={{ lg: 768, sm: 0 }}
            cols={{ lg: 24, sm: 6 }}
            draggableHandle=".overview-drag-handle"
            isDraggable={edit}
            isResizable={edit}
            resizeHandles={['s', 'e', 'se']}
            resizeHandle={<ResizeHandle />}
            margin={[24, 24]}
            onLayoutChange={(newLayout) => {
                if (edit) {
                    Highcharts.charts.map(chart => {
                        if (chart) {
                            chart.reflow();
                        }
                    });
                    setLayout({
                        ...layout,
                        data: rawData.map(item => {
                            const newItem = newLayout.find(a => a.i === item.id);
                            if (!newItem) return item;
                            return {
                                ...item,
                                x: newItem.x,
                                y: newItem.y,
                                h: newItem.h,
                                w: newItem.w,
                                minH: newItem.minH,
                                minW: newItem.minW
                            };
                        })
                    });
                }
            }}
            className="m-n3"
        >
            {data.map((item) => (
                <div key={item.id}>
                    {edit && (
                        <div className="widget-actions d-flex">
                            <div className="text-white bg-danger p-1 cursor-pointer line-height-1 mr-11"
                                onClick={() => openDeleteWidgetModal(item)}
                            >
                                <Trash data-testid="delete-widget-button" size={18} />
                            </div>
                            <div className="overview-drag-handle text-white bg-primary p-1 cursor-grab line-height-1">
                                <Move data-testid="drag-handle" size={18} />
                            </div>
                        </div>
                    )}
                    <Box className={`h-100 ${
                        (item.type === 'table' || item.type === 'matches') ? 'px-3 pt-3' : 'p-3'
                    } ${edit ? 'pointer-events-none border-primary' : ''}`}
                    >
                        <Visualisation type={item.type} data={item.data} id={item.id} />
                    </Box>
                </div>
            ))}
        </GridLayout>
    );
};
