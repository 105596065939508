import React from 'react';
import { Button } from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
import { client } from '../../auth0';

export const SSOLogin = ({ connectionName }) => {
    const { data: connectionInstance, loading } = useQuery(GET_CONNECTION_INSTANCE, {
        variables: { connectionName }
    });
    const loginWithCustomConnection = (e) => {
        e.preventDefault();
        const ssoInstanceId = connectionInstance?.getConnectionInstance;
        const redirectUrl = `${window.location.origin}/sso-auth?ssoInstanceId=${ssoInstanceId}&ssocn=${connectionName}`;
        client.authorize({
            connection: connectionName,
            responseType: 'token id_token',
            scope: 'openid profile email',
            redirectUri: redirectUrl,
        });
    };
    return (
        <Button type="button"
            color="primary"
            className="my-3"
            disabled={loading}
            onClick={loginWithCustomConnection}
        >Login with SSO
        </Button>
    );
};

export const GET_CONNECTION_INSTANCE = gql`
    query getConnectionInstance($connectionName: String!) {
        getConnectionInstance(connectionName: $connectionName)
    }
`;
