import { gql, useLazyQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Form, FormGroup, Label, Button } from 'reactstrap';
import { Input } from '../../components/Form/Input';
import { getParam } from '../../utils/urlParams';

export const EmailForm = ({ onChange, email: defaultEmail, children }) => {
    const [email, setEmail] = useState(getParam('ssoemail') || '');
    const [error, setError] = useState('');
    const [getConnectionDetails, { loading }] = useLazyQuery(GET_CONNECTION_DETAILS);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (loading) return;
        if (/\S+@\S+\.\S+/.test(email)) {
            getConnectionDetails({
                variables: { email }
            }).then(({ data }) => {
                if (!data?.getConnectionDetails?.userExists) {
                    setError('User does not exist. Please contact your administrator');
                } else {
                    onChange({ email, connectionName: data?.getConnectionDetails?.connectionName });
                }
            });
        } else {
            setError('Please enter a valid email address');
        }
    };
    const shouldShowContinue = defaultEmail ? email !== defaultEmail : true;
    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label className="mb-1">Email</Label>
                    <Input value={email}
                        onChange={(e) => { setEmail(e.target.value); setError(''); }}
                        type="email"
                        required
                        error={error}
                        state={error ? 'error' : ''}
                        testId="emailInput"
                    />
                </FormGroup>
                {shouldShowContinue && <Button color="primary" type="submit" className="mt-2" disabled={loading}>Continue</Button>}
            </Form>
            {!shouldShowContinue && children}
        </div>
    );
};

export const GET_CONNECTION_DETAILS = gql`
    query getConnectionDetails($email: String!) {
        getConnectionDetails(email: $email) {
            connectionName
            userExists
        }
    }
`;
