import { useQuery } from '@apollo/client';
import { GET_INSTANCE } from '../../graphql';

type UseGetInstanceProps = {
    instanceId?: string,
    skip?: boolean
}

export const useGetInstance = ({ instanceId, skip = false }: UseGetInstanceProps) => {
    const { data, loading, refetch: refetchInstance } = useQuery(GET_INSTANCE, {
        variables: { id: instanceId as string },
        skip: !!skip
    });
    const instance = data?.instance;

    return {
        instance,
        refetchInstance,
        loading
    };
};
