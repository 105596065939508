import React, { useState } from 'react';
import {
    Alert,
    Button,
    Form,
    FormGroup,
    Label
} from 'reactstrap';
import { client } from '../../auth0';
import { PasswordInput } from '../../components/Form/PasswordInput';

export const LoginForm = ({ email, setForgotPasswordFlow }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const authenticate = async (e) => {
        e.preventDefault();
        setLoading(true);
        client.login({
            email,
            password,
            responseType: 'id_token',
            scope: 'openid profile email user_metadata',
            realm: 'Username-Password-Authentication',
            redirectUri: window.location.origin,
        }, (err) => {
            if (err) {
                setError(err.code === 'access_denied' ? 'Wrong password' : 'Something went wrong, please try again.');
                setLoading(false);
            } else {
                window.location.replace(window.location.origin);
            }
        });
    };

    return (
        <Form onSubmit={authenticate} autoComplete="false">
            <FormGroup className="mt-3 mb-3">
                <Label className="mb-1">Password</Label>
                <PasswordInput password={password} setPassword={setPassword} required />
            </FormGroup>
            <Button type="submit" color="primary" disabled={loading}>Login</Button>
            <Button color="link" onClick={() => setForgotPasswordFlow(true)} className="d-block mt-2 p-0">
                Forgot your password?
            </Button>
            <Alert color="danger" isOpen={!!error} className="mt-3">
                {typeof error === 'string' ? error : 'Something went wrong, please try again.'}
            </Alert>
        </Form>
    );
};
