import React from 'react';
import { Col, Row } from 'reactstrap';
import { LogoL } from '../../assets/svg/logo';
import { WhatsNewCarousel } from './WhatsNewCarousel';

export const AuthLayout = ({ children }) => (
    <Row className="auth-layout">
        <Col xs={12} lg={7} className="border-right auth-page-background">
            <div className="p-5">
                <div className="d-flex align-items-center mb-5">
                    <LogoL />
                    <h2 className="ml-2 my-0 text-logo dark text-dark">Intelligence</h2>
                </div>
                <WhatsNewCarousel />
            </div>
        </Col>
        <Col xs={12} lg={5}>
            {children}
        </Col>
    </Row>
);
